import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

const SingleMachineBarInfo = ({ summary, selectedDate }) => {

  console.log(summary)
  const calculatePercentage = (runtime, losstime) => {
    let a = runtime.split(":");
    let b = losstime.split(":");
    let runTimeInSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    let lossTimeInSeconds = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
    const totalRunTime = Math.floor((100 * runTimeInSeconds) / (24 * 3600));
    const totalLossTime = Math.floor((100 * lossTimeInSeconds) / (24 * 3600));

    const data_array = [];
    const data = { name: "test", run: totalRunTime, loss: totalLossTime };
    data_array.push(data);

    return data_array;
  };
  const calculatePercentageFix = (runtime, losstime) => {
    let a = runtime.split(":");
    let b = losstime.split(":");
    let runTimeInSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    let lossTimeInSeconds = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
    const totalRunTime = ((100 * runTimeInSeconds) / (runTimeInSeconds+lossTimeInSeconds));
    const totalLossTime = ((100 * lossTimeInSeconds) / (runTimeInSeconds+lossTimeInSeconds));

    const data_array = [];
    const data = { name: "test", run: totalRunTime.toFixed(2), loss: totalLossTime.toFixed(2) };
    data_array.push(data);

    return data_array;
  };

  const data = calculatePercentageFix(summary.totalRuntime, summary.totalLossTime);
  // console.log("data", data);
  return (
    <Wrapper>
      <h6 className="text-capitalize">
        {" "}
        {/* {moment(selectedDate).format("D MMM, yy - dddd")} */}
      </h6>
      <div className="indicator">
        <div className="box" style={{ backgroundColor: "#373B6A" }}></div>{" "}
        <p className="time">
          Production : <span>{summary.production}</span>
        </p>
      </div>
      <div className="indicator">
        <div className="box" style={{ backgroundColor: "#373B6A" }}></div>{" "}
        <p className="time">
          Run Time : <span>{summary.totalRuntime}</span>
        </p>
      </div>
      <div className="indicator">
        <div className="box" style={{ backgroundColor: "#DB0909" }}></div>{" "}
        <p className="time">
          Loss Time :{" "}
          <span style={{ color: "#DB0909" }}>{summary.totalLossTime}</span>
        </p>
      </div>

      <ResponsiveContainer height={50} width={"100%"}>
        <BarChart
          layout="vertical"
          data={data}
          style={{
            marginLeft: "-5px",
            marginTop: "-3px",
          }}
        >
          <XAxis hide type="number" />
          <YAxis type="category" dataKey="name" hide />
          <Bar dataKey="run" fill="#373B6A" stackId="a" radius={[17, 0, 0, 17]}/>
          <Bar dataKey="loss" fill="#DB0909" stackId="a" radius={[0, 17, 17, 0]}/>
        </BarChart>
      </ResponsiveContainer>
      {data[0].run > 0 ? (
        <div className="d-flex justify-content-between mb-2">
          <p style={{ color: "#373B6A" }}>{data[0].run}%</p>
          <p style={{ color: "#DB0909" }}>{data[0].loss}%</p>
        </div>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 140px;

  .indicator {
    display: flex;
    /* margin:2px; */
  }
  .box {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
    border: 0;
  }

  .time {
    font-weight: 600;
    color: #04122c;
  }
`;

export default SingleMachineBarInfo;
