import { useEffect, useState } from "react";
import filterFloor from "../assets/filterFloor.svg";
import search from "../assets/search.svg";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";

const FloorTopbar = (props) => {
  const { user, http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [floors, setFloors] = useState([]);
  const [displayFloors, setDisplayFloors] = useState("none");

  useEffect(() => {
    if (user) {
      props.setSelectedFloorId(user.default_floor);
    }
  }, [user]);
  useEffect(() => {
    setFloorsData();
  }, [selectedFactoryId]);

  const setFloorsData = async () => {
    const { data } = await http.get(
      `/factory-floors?factory_id=${selectedFactoryId}&floor_id=${props.selectedFloorId}`
    );
    // console.log("setFloorsData:", data);
    // data && setFloors(Object.values(data));

    const myArray = Object.entries(data).map(([key, value]) => ({ name: value, id: key }));
    setFloors(myArray);
    if(myArray.length > 0) {
        props.setSelectedFloorId(myArray[0].id);
    }
  };

  const handleSelectFloor = (value)=>{
    // console.log("setSelectedFloorId:", value)
    props.setSelectedFloorId(value);
    setDisplayFloors("none")
  }
  return (
    <div style={floorStyle}>
      <div className="d-flex justify-content-start"  style={{ maxWidth: "1100px", margin: "0 auto" }}>
        <div
          className="d-flex justify-content-between border border-1 rounded-pill align-middle fw-bold"
          style={{
            width: "200px",
            marginTop: "6px",
            // marginLeft: "10px",
            fontSize: "13px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            displayFloors == "none"
              ? setDisplayFloors("block")
              : setDisplayFloors("none");
          }}
        >
          <span className="ms-4 p-2 text-dark">Filter By Floor Name </span>
          <img
            src={filterFloor}
            style={{ width: "15px", marginLeft: "2px", marginRight: "10px" }}
          />
        </div>
        <div style={{ position: "relative" }}>
          <input
            type="type"
            style={{
              top: "50%",
              position: "absolute",
              transform: "translateY(-50%)",
              height: "35px",
              marginTop: "2px",
              width: "300px",
              fontSize:"13px"

            }}
            className="ms-4 ps-3 pe-4 border border-1 rounded-pill"
          />
          <img src={search} className="serach-icon" />
        </div>
      </div>
      <div style={listFloor(displayFloors)} >
        {floors.map((f, i) => {
          if (i != floors.length - 1)
            return (
              <div
                key={i}
                style={{
                //   marginLeft: "10px",
                  fontSize: "13px",
                  height: "30px",
                  cursor: "pointer",
                }}
                className="border-bottom border-1 p-2"
                onClick={e=>handleSelectFloor(f.id)}
              >
                {f.name}
              </div>
            );
          else
            return (
              <div
                key={i}
                style={{
                //   marginLeft: "10px",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                className="p-2"
                onClick={e=>handleSelectFloor(f.id)}
              >
                {f.name}
              </div>
            );
        })}
      </div>
    </div>
  );
};
const floorStyle = {
  backgroundColor: "#FFFFFF",
  height: "50px",
  width: "100%",
  marginTop: "10px",
  marginBottom: "10px",
  overflow: "hidden",
  
  //   position:"relative"
};
const listFloor = (display) => {
  return {
    position: "absolute",
    top: "130px",
    left:"520px",
    backgroundColor: "#FFFF",
    width: "230px",
    zIndex: "100000",
    borderRadius: "5px",
    // padding: "10px",
    border: "1px solid #DEE2E6",
    display: display,
  };
};
export default FloorTopbar;
