import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import SideNav from "./components/SideNav";

import { AppProvider } from "./context/app_context";
import { UserProvider } from "./context/user_context";
import Error from "./pages/Error";
import LivePage from "./pages/LivePage";
import LoginPage from "./pages/LoginPage";
import Overview from "./pages/OverviewPage";

import RegistrationPage from "./pages/RegistrationPage";
import ReportPage from "./pages/ReportPage";

function App() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <BrowserRouter>
      <UserProvider>
        <AppProvider>
          {!user ? (
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/" element={<Overview />} />
              <Route path="*" element={<Error />} />
            </Routes>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <SideNav />
              </div>

              <div style={{width:"100%"}}>
                <Navbar />
                <div style={{ margin: " 0 auto" }}>
                  <Routes>
                    <Route path="/" element={<ProtectedRoute />}>
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/register" element={<RegistrationPage />} />
                      <Route path="/" element={<Overview />} />
                      <Route path="live" element={<LivePage />} />
                      <Route path="reports" element={<ReportPage />} />
                    </Route>
                    <Route path="*" element={<Error />} />
                  </Routes>
                  <ToastContainer />
                  <Footer />
                </div>
              </div>
            </div>
          )}
        </AppProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
