import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import FloorTopbar from "../components/FloorTopbar";
import Motion from "../components/Motion";
import SelectMachineOrDate from "../components/SelectMachineOrDate";
import SingleMachineBarInfo from "../components/SingleMachineBarInfo";
import SingleMachineTableInfo from "../components/SingleMachineTableInfo";
import SortView from "../components/SortView";
import ViewSortingButton from "../components/ViewSortingButton";
import { useUserContext } from "../context/user_context";
import usePrevious from "../hooks/usePrevious";
import { axios_export_table_instance } from "../utils/axios";

const ReportPage = () => {
  const [graphView, setGraphView] = useState(true);
  const {selectedFactoryId} = useUserContext();
  const { user,http } = AuthUser();
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const changeView = (e) => {
    const viewType = e.target.id;
    if (viewType === "table_view") {
      setGraphView(false);
    }
    if (viewType === "graph_view") {
      setGraphView(true);
    }
  };

  
  
  const [report, setReport] = useState([]);

  const [summary, setSummary] = useState({});

  const [machineDetails, setMachineDetails] = useState({});

  const currentDate = moment().format("YYYY-MM-DD");

  const [date, setDate] = useState(currentDate);

  const [selectedDate, setSelectedDate] = useState(currentDate);

  const [deviceId, setDeviceId] = useState("");

  const [machineId, setMachineId] = useState("");

  const prevDeviceId = usePrevious(deviceId);

  const [machines, setMachines] = useState([]);

  const [loading, setLoading] = useState(false);

  //get machine information (id,identifier)
  const getAllMachines = async () => {
    
    const { data } = await http.get(`/factory-machine-list?factory_id=${selectedFactoryId}`);
    data.length > 0 ? setMachines(data) : setMachines([]);
    
    //set default deviceId
    data.length > 0 ? setDeviceId( data[0].identifier) : setDeviceId("");
    data.length > 0 ? setMachineId( data[0].machineId) : setMachineId("");
  };

  useEffect(() => {
    (async () => {
      await getAllMachines();
    })();
  }, [selectedFactoryId]);

  useEffect(() => {
    const getMachineReport = async (date, deviceId) => {
     

      if (date && date !== "" && deviceId && deviceId !== "") {
        const { data } =  await http.get(`/machine-report?reportDate=${date}&deviceId=${deviceId}&factory_id=${selectedFactoryId}`);
 
        if (data) {
      
          setSummary(data.summary);
          setMachineDetails(data.machineDetails);

          setReport(Object.entries(data["report-data"]));
          
        }
      }
    };

    if (prevDeviceId === "") {
      getMachineReport(date, deviceId).catch(console.error);
    }
  }, [deviceId, date, prevDeviceId, selectedFactoryId]);

  const handleOnDeviceChange = async (e) => {
    setLoading(true);
    if (date && date !== "" && e.target.value && e.target.value !== "") {
      setDeviceId(e.target.value);
      setMachineId(e.target.options[e.target.selectedIndex].text);
      const { data } = selectedFactoryId && await http.get(`/machine-report?reportDate=${date}&deviceId=${e.target.value}&factory_id=${selectedFactoryId}`);
      if (data) {
        setMachineDetails(data.machineDetails);
        setSummary(data.summary);
        setReport(Object.entries(data["report-data"]));
        // setReport(([data["report-data"]].map(Object.values)));
      }
      setLoading(false);
    }
  };

  const handleOnDateChange = async (e) => {
   
    setLoading(true);
    if (
      e.target.value &&
      e.target.value !== "" &&
      deviceId &&
      deviceId !== ""
    ) {
      setDate(e.target.value);
      setSelectedDate(e.target.value);
      const { data } = await http.get(`/machine-report?reportDate=${e.target.value}&deviceId=${deviceId}&factory_id=${selectedFactoryId}`);
      if (data) {
        setSummary(data.summary);
        setReport(Object.entries(data["report-data"]));
       
      }
      setLoading(false);
    }
  };

  //export csv file
  const handleExport = async() => {
    const params = {
      responseType: 'blob'
    }
   await axios_export_table_instance.get(`/machine-report?reportDate=${date}&deviceId=${deviceId}&export=1&factory_id=${selectedFactoryId}`, params)
       .then((response) => {
         /*let fileName = response.headers["Content-Disposition"].split("filename=")[1];
         if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
           window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                   { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
               ),
               fileName
           );
         } else {
           const url = window.URL.createObjectURL(new Blob([response.data],
               { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download',
               response.headers["content-disposition"].split("filename=")[1]);
           document.body.appendChild(link);
           link.click();
         }*/

         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', machineId+".xlsx");
         document.body.appendChild(link);
         link.click();

       });


    // window.open(
    //   `${URL}/v2/machine-report?reportDate=${date}&deviceId=${deviceId}&export&factory_id=${selectedFactoryId}`,
    //   "_self"
    // );
  };

  return (
    <Motion>
      <FloorTopbar
        selectedFloorId={selectedFloorId}
        setSelectedFloorId={setSelectedFloorId}
      />
      <Wrapper className="page-100">
        <div className="section">
          <div className="section-center">
            {/* <Hero title='Reports' /> */}

            {/* machine-wise report */}
            <h4>machine-wise report</h4>
            <div className="row">
              <div className="col-md-6">
                <SelectMachineOrDate
                  machines={machines}
                  date={date}
                  setDate={setDate}
                  deviceId={deviceId}
                  setDeviceId={setDeviceId}
                  handleOnDateChange={handleOnDateChange}
                  handleOnDeviceChange={handleOnDeviceChange}
                />
              </div>
              <div className="col-md-6 text-md-end my-auto">
                <ViewSortingButton
                  changeView={changeView}
                  graph_view={graphView}
                />
              </div>
            </div>

            <main>
              <div className="row">
                <div className="col-md-6">
                  <SingleMachineTableInfo
                    machineDetails={machineDetails}
                    summary={summary}
                    deviceId={deviceId}
                    machineId={machineId}
                  />
                </div>
                <div className="col-md-6 ">
                  {Object.keys(summary).length > 0 && summary && !loading ? (
                    <SingleMachineBarInfo
                      selectedDate={selectedDate}
                      summary={summary}
                    />
                  ) : (
                    <Skeleton variant="rect" width="100%" height={130} />
                  )}
                </div>
              </div>
            </main>

            <main>
              <div className="row">
                <SortView
                  report={report}
                  handleExport={handleExport}
                  changeView={changeView}
                  graph_view={graphView}
                />
              </div>
            </main>
          </div>
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.section``;
export default ReportPage;
