import React, { useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link } from "react-router-dom";
import energy from "../assets/energy.svg";
import env from "../assets/env.svg";
import floor from "../assets/floor_map.svg";
import help from "../assets/help.svg";
import trnspLogo from "../assets/logo.svg";
import machine from "../assets/machine.svg";
import overview from "../assets/overview.svg";
import reports from "../assets/reports.svg";
import settings from "../assets/settings.svg";

const SideNav = () => {
  const [isHoveredEnv, setIsHoveredEnv] = useState(false);
  const [isHoveredEng, setIsHoveredEng] = useState(false);
  const [isHoveredStg, setIsHoveredStg] = useState(false);
  const [isHoveredMcg, setIsHoveredMcg] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <nav className="side-nav fw-light">
      <ul style={{ listStyleType: "none", padding: "2px" }}>
        <li>
          <Link to="/">
            <img src={trnspLogo} />
          </Link>
        </li>
        <li style={{ marginBottom: "20px" }}>
          {/* <Link to="/live"> */}
          <div>
            <div
              className="d-flex justify-content-start"
              onClick={(e) => {
                setShow(!show);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src={machine}
                // onMouseOver={() => setIsHoveredEnv(true)}
                // onMouseOut={() => setIsHoveredEnv(false)}
                style={{ width: "20px", marginLeft: "10px" }}
              />
              <p
                style={{
                  textAlign: "start",
                  color: "white",
                  // marginRight: "10%",
                  marginLeft: "10%",
                  marginTop: "5px",
                }}
              >
                Machines
              </p>

              <TiArrowSortedDown
                style={{ color: "white", margin: "auto" }}
                size={30}
              />
            </div>

            <div
              style={{
                color: "white",
                marginLeft: "20%",
                display: show ? "block" : "none",
              }}
            >
              <Link to="/">
                <div
                  className="d-flex justify-content-start"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={overview}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />

                  <p
                    style={{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      marginTop: "5px",
                    }}
                  >
                    Overview
                  </p>
                </div>
              </Link>
              <Link to="live">
                <div
                  className="d-flex justify-content-start"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={floor}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                  <p
                    style={{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      marginTop: "5px",
                    }}
                  >
                    Floor Map
                  </p>
                </div>
              </Link>
              <Link to="reports">
                <div
                  className="d-flex justify-content-start"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={reports}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                  <p
                    style={{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      marginTop: "5px",
                    }}
                  >
                    Reports
                  </p>
                </div>
              </Link>
            </div>
          </div>

          {/* </Link> */}
        </li>
        <li style={{ marginBottom: "20px" }}>
          <div
            className="d-flex justify-content-start"
            style={{ cursor: "pointer" }}
          >
            <img
              src={env}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Environment
            </p>
          </div>
        </li>

        <li style={{ marginBottom: "20px" }}>
          <div
            className="d-flex justify-content-start"
            style={{ cursor: "pointer" }}
          >
            <img
              src={energy}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Energy
            </p>
          </div>
        </li>
        <li>
          <div
            className="d-flex justify-content-start"
            style={{ cursor: "pointer" }}
          >
            <img
              src={settings}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Settings
            </p>
          </div>
        </li>
        <li>
          <div
            className="d-flex justify-content-start mt-4"
            style={{ cursor: "pointer" }}
          >
            <img
              src={help}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Help center
            </p>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
