
import React from 'react';
import { FaAlignRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import proPic from '../assets/Image.svg';
import { useAppContext } from '../context/app_context';
import { links } from '../utils/constants';
import NotificationButton from './NotificationButton';
const Navbar = () => {
  const location = useLocation();
  const { openSidebar, closeSidebar } = useAppContext();
  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log("links:", links);
  return (
    <>
      {user ? (
        <Wrapper className="section">
          <div className="nav-center section-center d-flex justify-content-between">
            <div className="nav-header">
              {/* <Link to="/" onClick={closeSidebar}>
                <img src={logo} alt="Factory Next" />
              </Link> */}
              {user &&
                links.map((link) => {
                  return (
                    link.url == location.pathname ? 
                    <span className='fw-bolder fs-4 text-capitalize'>{link.text}</span>
                    :null
                  );
                })}
              <button
                className="nav-toggle"
                type="button"
                onClick={openSidebar}
              >
                <FaAlignRight />
              </button>
            </div>
                <div className="d-flex justify-content-start">
                  <NotificationButton />
                  <img src={proPic} style={{cursor:"pointer"}}/>
                </div>
            

          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.section`
  background-color: #FFFFFF;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #DEE2E6;
  .nav-center {
    width: 100vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 165px;
      margin-left: -15px;
    }
  }

  .nav-toggle {
    /* margin-top:400px; */
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
    font-weight: 600;   
  }
  .notification-btn-wrapper {
    display: none;
  }
  a {
    color: #979BB2 !important;
    font-size: 18px !important;
  }
  a.active {
    // border-bottom: 2px solid #17a2b8;
    color: #04122C !important;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 0.9rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid #17a2b8;
        }
      }
    }
    .notification-btn-wrapper {
      display: grid;
    }
  }
`;
export default Navbar;
